import { getInstance } from "./index";
import store from "@/store";
import roundToNearestMinutes from "date-fns/roundToNearestMinutes";
import router from "@/router";
import Vue from "vue";

export const authGuard = (to, from, next) => {
  const authService = getInstance();
  clearTimeout(store.state.timer);
  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      Vue.nextTick(() => {
        var title = "Blecon Console - " + to.meta.title;
        document.title = title || "Blecon Console";
        var _hsq = (window._hsq = window._hsq || []);
        _hsq.push(["setPath", to.fullPath]);
        _hsq.push(["trackPageView"]);
      });

      return next();
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};
