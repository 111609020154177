<template>
  <b-modal
    @hide="$emit('close')"
    title="Configure AWS S3"
    visible="visible"
    size="lg"
    @shown="touchAllFields"
  >
    <div class="mb-3">
      To learn how to configure AWS S3, please refer to the
      <a href="https://developer.blecon.net/integrations/aws-s3" target="_new"
        >documentation.</a
      >
    </div>
    <b-form>
      <b-row>
        <b-col>
          <b-form-group
            size="sm"
            id="input-group-1"
            label="Integration name"
            label-for="input-1"
            description="Your name for this integration"
          >
            <b-form-input
              size="sm"
              id="input-2"
              v-model="$v.value.localName.$model"
              type="text"
              :state="!$v.value.localName.$error"
            >
            </b-form-input>
            <div class="form-error" v-if="$v.value.localName.$error">
              {{ $v.value.localName.$errors[0].$message }}
            </div>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Namespace"
            description="Blecon requests must be configured to use this namespace"
          >
            <b-form-input
              v-model="$v.value.localConfig.namespace.$model"
              placeholder="Enter Namespace"
              size="sm"
              :state="!$v.value.localConfig.namespace.$error"
            ></b-form-input>
            <div
              class="form-error"
              v-if="$v.value.localConfig.namespace.$error"
            >
              Valid namespace is required
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="AWS IAM Role ARN"
            description="The ARN of the role Blecon will assume to access your S3 bucket. Example: arn:aws:iam::123456:role/blecon-aws-role"
          >
            <b-form-input
              v-model="$v.value.localConfig.role_arn.$model"
              placeholder="Enter ARN"
              size="sm"
              :state="!$v.value.localConfig.role_arn.$error"
            ></b-form-input>
            <div class="form-error" v-if="$v.value.localConfig.role_arn.$error">
              Role ARN is required
            </div>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="AWS Region"
            description="The region of the bucket you want to use. Example: us-west-2"
          >
            <b-form-input
              v-model="$v.value.localConfig.region.$model"
              placeholder="Enter Region Name"
              size="sm"
              :state="!$v.value.localConfig.region.$error"
            ></b-form-input>
            <div class="form-error" v-if="$v.value.localConfig.region.$error">
              Region Name is required
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group
        label="Bucket Name"
        description="The name of the bucket you want to use"
      >
        <b-form-input
          v-model="$v.value.localConfig.bucket_name.$model"
          placeholder="Enter Bucket Name"
          size="sm"
          :state="!$v.value.localConfig.bucket_name.$error"
        ></b-form-input>
        <div class="form-error" v-if="$v.value.localConfig.bucket_name.$error">
          Bucket Name is required
        </div>
      </b-form-group>
    </b-form>
    <div v-if="!routesValid" class="alert alert-danger mt-3">
      <span
        >Invalid configuration. Namespace already claimed by other
        integrations.</span
      >
    </div>

    <template #modal-footer>
      <b-button variant="secondary" @click="$emit('close')">Cancel</b-button>
      <b-button
        variant="primary"
        :disabled="$v.value.$errors.length > 0 || !routesValid"
        @click="saveConfig"
        >Save</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers, url } from "@vuelidate/validators";
import { uniqueNamespace, checkValidRoutes } from "@/utils/validators";
import { ro } from "date-fns/locale";

export default {
  props: {
    config: {
      type: Object,
      required: true
    },
    integration: {
      type: Object,
      required: true
    },
    installedIntegrations: {
      type: Array,
      required: true
    },
    networkConfig: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      localConfig: { ...this.config }, // Local copy of config to edit
      localName: this.integration.name,
      visible: true, // Modal is visible by default
      routesValid: true
    };
  },

  validations() {
    return {
      localName: {
        required,
        uniqueName: helpers.withMessage(
          "Unique name required",
          value =>
            !this.installedIntegrations.find(
              i =>
                i.name === value && i.config_id !== this.integration.config_id
            )
        )
      },
      localConfig: {
        namespace: {
          required,
          maxLength: maxLength(20),
          alphaNumeric: helpers.withMessage(
            "Only alphanumeric characters are allowed",
            value => /^[a-z0-9-]*$/.test(value) // Regex to validate single word, alphanumeric only
          )
        },
        role_arn: {
          required
        },
        bucket_name: {
          required
        },
        region: {
          required
        }
      }
    };
  },
  created() {
    this.$log.debug("Integration config", this.config);
    this.$v = useVuelidate(this.validations, this);
    this.integration.url = "https://aws.integrations.blecon.net/s3";
  },

  watch: {
    localConfig: {
      deep: true,
      handler(newVal, OldVal) {
        this.routesValid = checkValidRoutes(
          this.getNewRoutes(),
          this.networkConfig.routes
        );
      }
    }
  },

  methods: {
    getNewRoutes() {
      var headers = [];
      var routes = [];
      if (this.localConfig.namespace) {
        routes.push({
          filter_events: "device.*." + this.localConfig.namespace + ".*",
          headers: headers,
          url: this.integration.url
        });
      }
      return routes;
    },

    touchAllFields() {
      this.$v.value.$touch(); // Mark all fields as "touched"
    },

    saveConfig() {
      this.$log.debug("Saving config", this.localConfig);
      this.$v.value.$touch(); // Mark all fields as "touched"
      var routes = this.getNewRoutes();
      this.$log.debug("Saving config:: ", routes);
      this.$emit("save", this.localConfig, this.localName, routes); // Emit the updated config back to the parent
    }
  }
};
</script>
