<template>
  <b-container>
    <div class="b-main-content">
      <h2>Registering a device</h2>
    </div>
  </b-container>
</template>
<script>
import router from "@/router";
import store from "@/store";

export default {
  data() {
    return {
      postData: {}
    };
  },

  methods: {
    async registerDevice(id) {
      // register a device from a GET request

      //check to see if it's registered already
      var accessToken = await this.$auth.getTokenSilently();

      this.$axios
        .get(
          "/v1/accounts/" +
            store.state.accountid +
            "/devices/" +
            this.$route.params.deviceid
        )
        .then(response => {
          this.$notify({
            title: "This device is already registered to your account.",
            type: "success"
          });
          router.push({
            name: "viewdevice",
            params: {
              account: this.$store.state.accountid,
              devId: this.$route.params.deviceid
            }
          });
          return {};
        })
        .catch(err => {
          // 404 means we should try and register

          this.$axios
            .post("/v1/accounts/" + store.state.accountid + "/devices", {
              device_id: this.$route.params.deviceid,
              public_key: this.$route.query.k
            })
            .then(response => {
              this.$notify({
                title: "Device registered.",
                type: "success"
              });

              router.push({
                name: "viewdevice",
                params: {
                  account: this.$store.state.accountid,
                  devId: this.$route.params.deviceid
                }
              });
            })

            .catch(err => {
              //malformed request
              this.$notify({
                title: "Error while registering device.",
                type: "error"
              });
              router.push({
                name: "home",
                params: { account: this.$store.state.accountid }
              });
            });
        });
    }
  },
  created() {
    this.registerDevice();
  }
};
</script>
