import Vue from "vue";
import createAuth0Client from "@auth0/auth0-spa-js";
import store from "@/store";
import { roundToNearestMinutes } from "date-fns";
import router from "@/router";
import { bus } from "../main";

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null
      };
    },
    methods: {
      storeRedirect() {
        this.$log.debug("Storing redirect");
        var paramsObj = {};

        this.$log.debug(store.state.redirectPath);
        if (location.search) {
          var search = location.search.substring(1);
          paramsObj = JSON.parse(
            '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
            function(key, value) {
              return key === "" ? value : decodeURIComponent(value);
            }
          );
        }
        store.commit("redirectState", {
          path: window.location.pathname,
          params: paramsObj
        });
      },

      async loginWithPopup(options, config) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(options, config);
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.error = null;
        } catch (e) {
          this.error = e;
          // eslint-disable-next-line
          this.$log.error(e);
        } finally {
          this.popupOpen = false;
        }

        this.user = await this.auth0Client.getUser();
        this.isAuthenticated = true;
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        this.storeRedirect();
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      async getTokenSilently(o) {
        try {
          return await this.auth0Client.getTokenSilently(o);
        } catch (e) {
          if (e.error === "login_required") {
            this.loginWithRedirect();
          }
          if (e.error === "consent_required") {
            this.loginWithRedirect();
          }
          throw e;
        }
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        return this.auth0Client.logout(o);
      }
    },

    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object

      this.auth0Client = await createAuth0Client({
        ...options,
        client_id: options.clientId,
        redirect_uri: redirectUri
      });

      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback();

          this.error = null;

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)

          onRedirectCallback(appState);
        }
      } catch (e) {
        this.$log.error(e);
        this.error = e;
      } finally {
        // Initialize our internal authentication state
        this.$log.debug("Starting login");

        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();

        if (this.isAuthenticated) {
          this.$log.debug("Finished auth and got user from auth0");
          if (store.state.redirectPath.length) {
            this.$log.debug("Redirecting to: " + store.state.redirectPath);

            router
              .push({
                path: store.state.redirectPath,
                query: store.state.redirectParams
              })
              .catch();
          }
          //clear redirect state once we have redirected or we will redirect forever
          this.$log.debug("Completed redirect, and will clear redirect");
          store.commit("redirectState", {
            path: "",
            params: undefined
          });
          if (store.state.user.sub != this.user.sub) {
            this.$log.debug("User changed, clearing state");
            localStorage.clear();
          }
        }
        store.commit("user", {
          user: this.user
        });
        //load widget without token as that is a hubspot pro feature.
        //See git history here for how it works with pro.

        bus.$emit("logged", "User logged in");
        // ensure this is saved so named url loads actually work

        this.loading = false;
        //clear redirect path here

        //outbound request interceptor
        this.$axios.interceptors.request.use(function(request) {
          if (request.nospin == undefined) {
            store.commit("spin");
          }

          request.params = request.params || {};
          request.params["account"] = store.state.accountid;
          //get rid of any null values
          if (request.data) {
            let data = Object.fromEntries(
              Object.entries(request.data).filter(([_, v]) => v != null)
            );
            request.data = data;
          }
          return request;
        });

        // Add a response/error interceptor
        this.$axios.interceptors.response.use(
          function(response) {
            store.commit("nospin");

            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
          },
          function(error) {
            store.commit("nospin");

            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (error.status === 401) {
              this.logout({
                returnTo: window.location.origin
              });
            }

            return Promise.reject(error);
          }
        );
      }
    }
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
};
