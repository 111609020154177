<template>
  <div>
    <b-modal id="modal-3" @hidden="onReset" title="Account Invites" hide-footer>
      <p>
        Send an invite link for this Account
      </p>
      <b-form @submit="createInvite" @reset="onReset">
        <b-form-group
          size="sm"
          id="input-group-1"
          label="Email"
          label-for="input-1"
        >
          <b-form-input
            size="sm"
            id="input-1"
            v-model="inviteForm.user"
            type="text"
          >
          </b-form-input>
        </b-form-group>
        <div class="float-right">
          <b-button type="reset">Cancel</b-button>
          &nbsp;
          <b-button type="submit" variant="primary">Send Invite</b-button>
        </div>
      </b-form>
    </b-modal>

    <b-card class="mt-3" flush>
      <template #header>
        <h5 class="mb-0">
          Account Invites<b-button
            :title="
              $store.state.accountLevel > 20 ? 'Insufficent permissions' : ''
            "
            :disabled="$store.state.accountLevel > 20"
            size="sm"
            variant="success"
            v-b-modal.modal-3
            class="float-right"
            >Send Invite</b-button
          >
        </h5>
      </template>

      <b-table
        striped
        hover
        small
        :items="invites"
        :fields="inviteFields"
        v-if="invites.length"
      >
        <template #cell(actions)="data">
          <b-button
            class="mr-3"
            size="sm"
            :title="
              $store.state.accountLevel > 20 ? 'Insufficent permissions' : ''
            "
            :disabled="$store.state.accountLevel > 20"
            v-b-modal.modal-invite-sent
            @click="resendInvite(data.item.code)"
            variant="primary"
            >Resend</b-button
          >
          <b-button
            size="sm"
            :title="
              $store.state.accountLevel > 20 ? 'Insufficent permissions' : ''
            "
            :disabled="$store.state.accountLevel > 20"
            v-b-modal.modal-invite-sent
            variant="danger"
            @click="deleteInvite(data.item.code)"
            >Delete</b-button
          >
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import NotificationTemplate from "../Notifications/NotificationTemplate";
import { bus } from "../../main";

export default {
  methods: {
    async resendInvite(code) {
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      this.$axios
        .post(
          "/accounts/" + this.$store.state.accountid + "/invites/" + code,
          {},
          { params: { action: "resend" } }
        )
        .then(response => {
          this.$notify({
            title: "Invite re-sent",
            type: "success"
          });

          this.getData();
        });
    },
    async deleteInvite(code) {
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      this.$root.$emit("bv::hide::modal", "modal-3");
      this.$axios
        .delete("/accounts/" + this.$store.state.accountid + "/invites/" + code)

        .then(response => {
          this.$notify({
            title: "Invite deleted",
            type: "success"
          });

          this.getData();
        });
    },
    onReset(event) {
      this.$root.$emit("bv::hide::modal", "modal-3");

      event.preventDefault();
      // Reset our form values to what we last got from the API
      this.inviteForm = {};
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    async createInvite(event) {
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      this.$root.$emit("bv::hide::modal", "modal-3");
      event.preventDefault();
      const validateEmail = email => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
      this.$log.debug(validateEmail(this.inviteForm.user));
      if (!validateEmail(this.inviteForm.user)) {
        this.$notify({
          title: "Failed to invite user. Please check the email address.",
          type: "error"
        });
        return;
      }
      await this.$axios
        .post(
          "/accounts/" + this.$store.state.accountid + "/invites",
          this.inviteForm
        )
        .then(response => {
          this.$notify({
            title: "Invite sent",
            type: "success"
          });

          this.getData();
        })
        .catch(error => {
          this.$notify({
            title:
              "Failed to invite user. " +
              (error.response.data.message ||
                "Please check the email address."),
            type: "error"
          });
        });
      //Reload from API (keeps state sane)
    },
    async getData() {
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      await this.$axios
        .get("/accounts/" + this.$store.state.accountid + "/invites")
        .then(response => {
          this.invites = response.data;
        });
      this.$emit("ready");
    }
  },
  async created() {
    bus.$on("switched-account", () => {
      this.getData();
    });
    await this.getData();
  },
  data() {
    return {
      inviteForm: {},
      invites: [],
      inviteFields: [
        "email",
        // A virtual column made up from two fields
        "actions"
      ]
    };
  }
};
</script>
