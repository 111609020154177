<template>
  <div>
    <net-explorer></net-explorer>
  </div>
</template>

<script>
//import shared from '@/components/Shared'
import router from "@/router";
import store from "@/store";
import NetExplorer from "./NetExplorer.vue";

import { format, formatDistance, formatRelative, subDays } from "date-fns";

export default {
  components: {
    NetExplorer
  },

  data() {
    return {
      api: {}
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      this.$axios
        .get(
          "/v1/accounts/" +
            store.state.accountid +
            "/networks/" +
            this.$route.params.networkId
        )
        .catch(err => {
          router.push("/404");
        })
        .then(response => {
          if (!response) return; // `response` is undefined

          this.api = response.data;
          var udate = new Date(parseInt(this.api.last_updated) * 1000);
          this.api.last_updated = formatDistance(udate, new Date(), {
            addSuffix: true
          });
          var date = new Date(parseInt(this.api.created) * 1000);
          this.api.created = formatDistance(date, new Date(), {
            addSuffix: true
          });
        });
    }
  }
};
</script>
