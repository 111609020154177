<template>
  <b-container>
    <br /><br /><br />
    <p class="text-center"></p>
    <h2 class="mb-5">Request a Blecon Account</h2>

    <div id="hubspotForm" v-once></div>
    <p></p>
  </b-container>
</template>
<script>
import { bus } from "../main";
import router from "@/router";

export default {
  mounted() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "19722094",
          formId: "8a14d1b0-d950-46b7-b2b1-dbec252057c8",
          target: "#hubspotForm"
        });
      }
    });

    window.addEventListener("message", event => {
      if (
        event.data.type === "hsFormCallback" &&
        event.data.eventName === "onFormReady"
      ) {
        document
          .getElementById("hs-form-iframe-0")
          .contentDocument.querySelector(
            'input[name="username"]'
          ).value = this.$auth.user.sub;
      }
    });
  },
  data() {
    return {
      form: {}
    };
  }
};
</script>
