<template>
  <b-container>
    <br /><br /><br />
    <p class="text-center"></p>
    <h2>Create a Blecon Account</h2>

    <p class="consolenotify"></p>
    <p>
      <b-form @submit="onSubmit" @reset="onReset">
        <b-form-group
          size="sm"
          id="input-group-1"
          label="Company name"
          label-for="name"
          description="This will be your account name"
        >
          <b-form-input size="sm" id="name" v-model="form.name" type="text">
          </b-form-input>
        </b-form-group>

        <b-form-group
          size="sm"
          id="input-group-2"
          label="Company website"
          label-for="website"
        >
          <b-form-input
            size="sm"
            id="website"
            v-model="form.website"
            type="text"
          >
          </b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary">Create Account</b-button>
      </b-form>
    </p>
  </b-container>
</template>
<script>
import { bus } from "../main";
import router from "@/router";

export default {
  data() {
    return {
      form: {}
    };
  },
  methods: {
    showModal(event) {
      this.$root.$emit("bv::show::modal", "modal-acct");
    },
    async onSubmit(event) {
      this.$root.$emit("bv::hide::modal", "modal-1");
      event.preventDefault();

      this.$axios
        .post("/accounts", this.form)

        .then(response => {
          this.$notify({
            title: "Account created. Welcome to Blecon!",
            type: "success"
          });

          bus.$emit("reload-accounts");
          bus.$on("accounts-reloaded", data => {
            router.push({
              name: "root"
            });
          });
        })
        .catch(err => {
          if (err.response != undefined) {
            this.$notify({
              title: "Error " + (err.response.data.message || ""),
              type: "error"
            });
          }
        });
      //Reload from API (keeps state sane)
    },
    onReset(event) {
      this.$root.$emit("bv::hide::modal", "modal-1");

      event.preventDefault();
      // Reset our form values to what we last got from the API
      this.form = {};
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>
