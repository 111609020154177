<template>
  <b-container>
    <b-breadcrumb>
      <b-breadcrumb-item href="#home">
        <router-link :to="{ name: 'root' }">Home </router-link>
      </b-breadcrumb-item>
    </b-breadcrumb>
    <div class="b-main-content">
      <b-card-group deck>
        <b-card no-body>
          <template #header>
            <h3 class="mb-0">
              Networks<b-button
                class="float-right"
                variant="success"
                size="sm"
                @hidden="onReset"
                :title="
                  $store.state.accountLevel > 20
                    ? 'Insufficent permissions'
                    : ''
                "
                :disabled="$store.state.accountLevel > 20"
                v-b-modal.modal-1
                >Create Network</b-button
              >
            </h3>
          </template>
          <b-list-group flush>
            <b-list-group-item
              v-for="i in items"
              :key="i.network_id"
              :to="{
                name: 'net_home',
                params: { networkId: i.network_id }
              }"
            >
              <div class="d-flex justify-content-between">
                <h5 class="mb-1">{{ i.name }}</h5>

                <small>{{ formatDate(i.last_updated) }}</small>
              </div>
              <div class="d-flex justify-content-between">
                <small class="text-muted">{{ i.network_id }}</small>
                <span class="badge badge-secondary badge-pill"
                  >{{ i.device_count }} devices
                </span>
              </div>
            </b-list-group-item>
          </b-list-group>

          <b-card-footer class="py-1 text-muted"
            >{{ items.length }} Networks</b-card-footer
          >
        </b-card>
        <b-card style="max-width: 19rem;">
          <h5 class="mb-3">Developer Resources</h5>
          <p>
            <a
              href="https://developer.blecon.net/getting-started/getting-started-index"
              ><i class="bx bx-book-open"></i> Getting Started Guides</a
            >
          </p>
          <p>
            <a href="https://developer.blecon.net/" target="docs"
              ><i class="bx bx-book-open"></i> Documentation</a
            >
          </p>

          <p>
            <a href="https://support.blecon.net/" target="_new"
              ><i class="bx bx-chat"></i> Support Portal</a
            >
          </p>
          <h5 class="mb-3 mt-4">Useful Links</h5>

          <p>
            <a href="https://www.blecon.net" target="_new">
              <i class="bx bx-world"></i> Blecon Website</a
            >
          </p>
        </b-card>
      </b-card-group>
    </div>
    <b-modal id="modal-1" @hidden="onReset" title="New Network" hide-footer>
      <p>
        Create a new network. A Blecon Network allows you to add devices and
        route requests to an API.
      </p>
      <b-form @submit="onSubmit" @reset="onReset">
        <b-form-group
          size="sm"
          id="input-group-1"
          label="Network Name"
          label-for="input-1"
          description="The name for the your new network"
        >
          <b-form-input size="sm" id="input-1" v-model="form.name" type="text">
          </b-form-input>
          <div class="form-error" v-if="$v.value.form.name.$error">
            Valid Network Name required
          </div>
        </b-form-group>

        <b-button
          type="submit"
          :disabled="$v.value.form.name.$error"
          variant="primary"
          >Create Network</b-button
        >
        &nbsp;
        <b-button type="reset">Cancel</b-button>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import router from "@/router";
import store from "@/store";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import { bus } from "../main";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  helpers,
  minLength,
  maxLength
} from "@vuelidate/validators";

export default {
  data() {
    return {
      fields: [
        { key: "network_id", label: "Network ID" },
        { key: "name", label: "Name" },
        { key: "last_updated", label: "Updated", formatter: "formatDate" },
        { key: "created", label: "Created", formatter: "formatDate" }
      ],

      items: [],
      form: {
        name: ""
      }
    };
  },
  validations() {
    return {
      form: {
        name: { required, minLength: minLength(1), maxLength: maxLength(30) }
      }
    };
  },
  created() {
    this.$v = useVuelidate(this.validations, this);

    bus.$on("switched-account", () => {
      this.getData();
    });
    this.getData();
  },
  mounted() {
    this.$log.debug(this.$v);
  },
  methods: {
    formatDate(value) {
      if (value) {
        var date = new Date(parseInt(value) * 1000);
        const formattedDate = formatDistance(date, new Date(), {
          addSuffix: true
        });
        return formattedDate;
      }
    },
    async getData() {
      this.$store.commit("spin");

      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      this.$axios
        .get("/v1/accounts/" + store.state.accountid + "/networks")
        .then(response => {
          this.items = response.data.networks;
          this.$store.commit("nospin");
        });
    },

    async onSubmit(event) {
      this.$v.value.$touch();
      if (this.$v.value.$invalid) {
        return;
      }

      this.$root.$emit("bv::hide::modal", "modal-1");
      event.preventDefault();
      this.$axios
        .post("/v1/accounts/" + store.state.accountid + "/networks", this.form)
        .then(response => {
          this.$notify({
            title: "Network created",
            type: "success"
          });
          router.push({
            name: "net_home",
            params: {
              networkId: response.data.network_id
            }
          });
        })

        .catch(err => {
          this.$notify({
            title: "Error " + (err.response.data.message || ""),
            type: "error"
          });
        });
      //Reload from API (keeps state sane)
    },
    onReset(event) {
      this.$root.$emit("bv::hide::modal", "modal-1");

      event.preventDefault();
      // Reset our form values to what we last got from the API
      this.form = {};
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>
