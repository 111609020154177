<template>
  <b-container>
    <b-modal
      id="modal-edit-gw"
      @hidden="onReset"
      :title="'Editing Hotspot'"
      hide-footer
    >
      <b-form @submit="editGW" @reset="onReset">
        <b-form-group
          size="sm"
          id="input-group-2"
          label="Hotspot Name"
          label-for="input-2"
          description="Optional hotspot name"
        >
          <b-form-input
            size="sm"
            name="name"
            id="input-2"
            v-model="gwForm.name"
            type="text"
          >
          </b-form-input>
        </b-form-group>
        <div class="text-right">
          <b-button type="submit" variant="primary">Save Changes</b-button>
        </div>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-create-gw"
      @hidden="onReset"
      title="Link Hotspot"
      hide-footer
    >
      <b-form @submit="createGW" @reset="onReset">
        <b-form-group
          size="sm"
          id="input-group-1"
          label="Hotspot ID"
          label-for="input-1"
          description="The Hotspot ID"
        >
          <b-form-input
            size="sm"
            name="hotspot_id"
            id="input-1"
            v-model="gwForm.hotspot_id"
            type="text"
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          size="sm"
          id="input-group-2"
          label="Hotspot Name"
          label-for="input-2"
          description="Optionally, give this hotspot a name"
        >
          <b-form-input
            size="sm"
            name="name"
            id="input-2"
            v-model="gwForm.name"
            type="text"
          >
          </b-form-input>
        </b-form-group>
        <div class="text-right">
          <b-button type="submit" variant="primary">Link Hotspot</b-button>
        </div>
      </b-form>
    </b-modal>

    <div class="b-main-content">
      <b-breadcrumb>
        <b-breadcrumb-item href="#home">
          <router-link :to="{ name: 'root' }">Home </router-link>
        </b-breadcrumb-item>

        <b-breadcrumb-item>
          <router-link :to="{ name: 'hotspots' }">Hotspots</router-link>
        </b-breadcrumb-item>
      </b-breadcrumb>

      <b-card>
        <template #header>
          <h3 class="mb-0">
            Hotspots
            <router-link
              :to="{
                name: 'hotspotlink'
              }"
            >
              <b-button
                class="float-right"
                variant="success"
                size="sm"
                @hidden="onReset"
                :title="
                  $store.state.accountLevel > 20
                    ? 'Insufficent permissions'
                    : ''
                "
                :disabled="$store.state.accountLevel > 20"
                v-b-modal.modal-create-gw
                >Link hotspot</b-button
              >
            </router-link>
          </h3>
        </template>

        <b-table striped hover small :fields="fields" :items="this.hotspots">
          <template #cell(actions)="data">
            <b-button
              size="sm"
              v-b-modal.modal-edit-gw
              @click="selectItem(data.item.hotspot_id)"
              variant="secondary"
              >Edit</b-button
            >
          </template>
        </b-table>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import { bus } from "../main";
import store from "@/store";

export default {
  components: {},
  computed: {
    accountLevel() {
      switch (this.$store.state.accountLevel) {
        case 0:
          return "Owner";
        case 20:
          return "Admin";
        case 30:
          return "Member";
        case 40:
          return "Installer";
      }
      return "";
    }
  },
  data() {
    return {
      fields: [
        { key: "hub_id", label: "Hotspot ID" },
        { key: "name", label: "Name" },
        {
          key: "health",
          label: "Health",
          tdClass: (value, key, item) =>
            value == "OK" ? "text-success" : "text-danger"
        },

        { key: "actions", label: "Actions" }
      ],
      gwForm: {
        name: ""
      },
      hotspots: [],
      selectedItem: ""
    };
  },

  methods: {
    selectItem(item) {
      this.selectedItem = item;
    },

    async getData() {
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());

      await this.$axios
        .get("/v1/accounts/" + store.state.accountid + "/hubs")
        .then(response => {
          this.hotspots = response.data;
        });
    },
    onReset() {
      this.getData();
    },
    async editGW(event) {
      this.$root.$emit("bv::hide::modal", "modal-edit-gw");
      event.preventDefault();
      var accessToken = await this.$auth.getTokenSilently();
      this.$axios
        .post("/hotspots/" + this.selectedItem, this.gwForm)
        .then(() => {
          //Reload from API
          this.getData();
        })
        .catch(err => {
          this.$notify({
            title: "Error " + (err.response.data.message || ""),
            type: "error"
          });
        });
    },
    async createGW(event) {
      this.$root.$emit("bv::hide::modal", "modal-create-gw");
      event.preventDefault();
      var accessToken = await this.$auth.getTokenSilently();
      this.$axios
        .post(
          "/v1/accounts/" +
            store.state.accountid +
            "/hubs/" +
            this.gwForm.hotspot_id,
          this.gwForm
        )
        .then(() => {
          //Reload from API
          this.getData();
        })
        .catch(err => {
          this.$notify({
            title: "Error " + (err.response.data.message || ""),
            type: "error"
          });
        });
    }
  },

  created() {
    bus.$on("switched-account", () => {
      this.getData();
    });
    this.getData();
  }
};
</script>
