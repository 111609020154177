<template>
  <b-container>
    <div class="b-main-content">
      <b-card header-tag="header" class="mb-2">
        <template #header>
          <h3 class="mb-0">
            Accepting an invite
          </h3>
        </template>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import router from "@/router";
import { bus } from "../../main";

export default {
  data() {
    return {};
  },
  async created() {
    this.$log.debug("accepting invite ");
    this.$axios.defaults.headers.common["authorization"] =
      "bearer " + (await this.$auth.getTokenSilently());
    await this.$axios
      .post(
        "/accounts/" + this.$route.query.account + "/accept-invite",
        {},
        { params: { code: this.$route.query.code } }
      )
      .then(response => {
        this.$notify({
          title: "You now have access to account " + this.$route.query.account,
          type: "success"
        });

        bus.$emit("reload-accounts");
        bus.$on("accounts-reloaded", data => {
          this.$router.push("/");
        });
      })

      .catch(err => {
        this.$notify({
          title: "Invalid invitation " + (err.response.data.message || ""),
          type: "error"
        });
      });
  }
};
</script>
