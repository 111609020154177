import { helpers } from "@vuelidate/validators";

const uniqueNamespace = installedNamespaces =>
  helpers.withMessage(
    "Namespace must be unique",
    value => !installedNamespaces.includes(value)
  );

const uniqueName = integrationNames =>
  helpers.withMessage(
    "Name must be unique",
    value => !integrationNames.includes(value)
  );

function checkValidRoutes(newRoutes, existingRoutes) {
  var existingFilters = existingRoutes.map(route => route.filter_events);
  var newFilters = newRoutes.map(route => route.filter_events);

  // if any of the new filter events are already in the existing routes, return false
  for (var i = 0; i < newFilters.length; i++) {
    if (existingFilters.includes(newFilters[i])) {
      return false;
    }
  }
  return true;
}

export { uniqueNamespace, checkValidRoutes };
