<template>
  <b-container>
    <br /><br /><br />
    <p class="text-center">
      404 : Page not found
    </p>
    <p class="text-center">
      <a href="mailto:support@blecon.net">support@blecon.net</a>
    </p>
    <br /><br /><br />
  </b-container>
</template>

<script>
export default {
  created() {
    this.$store.commit("nospin");
  }
};
</script>
