<template>
  <div>
    <b-modal
      id="modal-editmember"
      title="Edit Member"
      @hidden="onReset"
      hide-footer
    >
      <b-form @submit="saveEditMember" @reset="onReset">
        <b-form-group
          size="sm"
          id="input-group-1"
          label="User level"
          label-for="input-1"
        >
          <b-form-select
            size="sm"
            v-model="form.user_level"
            :options="options"
          ></b-form-select>
        </b-form-group>
        <b-button type="submit" variant="primary">Save</b-button>
        <b-button type="reset" class="ml-3">Cancel</b-button>

        &nbsp;
      </b-form>
    </b-modal>

    <b-card class="mt-3" flush>
      <template #header>
        <h5 class="mb-0">
          Account Members
        </h5>
      </template>

      <b-table striped hover small :items="members" :fields="memberFields">
        <template #cell(actions)="data">
          <b-button
            :title="
              $store.state.accountLevel > 20 ? 'Insufficent permissions' : ''
            "
            :disabled="$store.state.accountLevel > 20"
            size="sm"
            variant="warning"
            @click="deleteMember(data.item.user)"
            >Remove</b-button
          >
          <b-button
            v-if="data.item.user_level != 0"
            size="sm ml-2"
            v-b-modal.modal-editmember
            variant="warning"
            :title="
              $store.state.accountLevel > 20 ? 'Insufficent permissions' : ''
            "
            :disabled="$store.state.accountLevel > 20"
            @click="editMember(data.item.user)"
            >Edit</b-button
          >
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { bus } from "../../main";

export default {
  async created() {
    bus.$on("switched-account", () => {
      this.getData();
    });
    await this.getData();
  },
  methods: {
    async deleteMember(member) {
      this.$log.debug("Delete member", member);
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());

      await this.$axios
        .delete(
          "/accounts/" + this.$store.state.accountid + "/members/" + member
        )
        .then(response => {
          this.getData();
        })
        .catch(err => {
          this.$notify({
            title: "Error " + (err.response.data.message || ""),
            type: "error"
          });
        });
    },
    async saveEditMember(event) {
      event.preventDefault();

      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      this.$log.debug(this.form);
      await this.$axios
        .post(
          "/accounts/" +
            this.$store.state.accountid +
            "/members/" +
            this.form.user,
          { user_level: this.form.user_level }
        )
        .then(response => {
          this.$root.$emit("bv::hide::modal", "modal-editmember");

          this.getData();
        });
    },
    editMember(member) {
      for (var u in this.members) {
        if (member == this.members[u].user) {
          this.form = this.members[u];
        }
      }
    },
    async onReset() {
      await this.getData();
      this.form = {};
      this.$root.$emit("bv::hide::modal", "modal-editmember");
    },
    formatUserLevel(level) {
      switch (level) {
        case 0:
          return "Owner";
        case 20:
          return "Admin";
        case 30:
          return "Member";
        case 40:
          return "Installer";
      }
    },
    async getData() {
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());

      await this.$axios
        .get("/accounts/" + this.$store.state.accountid + "/members")
        .then(response => {
          this.members = response.data;
        });
    }
  },
  data() {
    return {
      options: [
        { value: 20, text: "Administrator" },
        { value: 30, text: "Member" },
        { value: 40, text: "Installer" }
      ],
      form: {},
      members: [],
      memberFields: [
        { key: "email", label: "Email" },
        {
          key: "user_level",
          label: "Level",
          formatter: "formatUserLevel"
        },
        { key: "actions", label: "Actions" }
      ]
    };
  }
};
</script>
