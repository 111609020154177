<template>
  <b-container>
    <div class="b-main-content">
      <b-breadcrumb>
        <b-breadcrumb-item href="#home">
          <router-link :to="{ name: 'root' }">Home </router-link>
        </b-breadcrumb-item>

        <b-breadcrumb-item>
          <router-link :to="{ name: 'gateways' }">Gateways</router-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          Link gateway to account
        </b-breadcrumb-item>
      </b-breadcrumb>

      <b-card>
        <template #header>
          <h3 class="mb-0">
            Link Gateway
          </h3>
        </template>

        <b-form @submit="createGW" @reset="onReset">
          <b-form-group
            size="sm"
            id="input-group-1"
            label="Gateway ID"
            label-for="input-1"
            description="The Gateway ID"
          >
            <b-form-input
              size="sm"
              name="gateway_id"
              id="input-1"
              v-model="gwForm.gateway_id"
              type="text"
            >
            </b-form-input>
          </b-form-group>

          <b-form-group
            size="sm"
            id="input-group-2"
            label="Gateway Name"
            label-for="input-2"
            description="Optionally, give this gateway a name"
          >
            <b-form-input
              size="sm"
              name="name"
              id="input-2"
              v-model="gwForm.name"
              type="text"
            >
            </b-form-input>
          </b-form-group>
          <div class="text-right">
            <b-button type="submit" variant="primary">Link Gateway</b-button>
          </div>
        </b-form>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import { bus } from "../main";
import router from "@/router";
import store from "@/store";

export default {
  components: {},
  computed: {
    accountLevel() {
      switch (this.$store.state.accountLevel) {
        case 0:
          return "Owner";
        case 20:
          return "Admin";
        case 30:
          return "Member";
        case 40:
          return "Installer";
      }
      return "";
    }
  },
  data() {
    return {
      fields: [
        { key: "gateway_id", label: "Gateway ID" },
        { key: "name", label: "Name" },
        { key: "health", label: "Health" },
        { key: "actions", label: "Actions" }
      ],
      gwForm: {
        name: "",
        gateway_id: this.$route.query.device_id
      },
      gateways: [],
      selectedItem: ""
    };
  },

  methods: {
    selectItem(item) {
      this.selectedItem = item;
    },

    async getData() {
      //redirect if gw exists

      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      await this.$axios.get("/gateways").then(response => {
        this.gateways = response.data;
      });
    },
    onReset() {
      this.getData();
    },
    async createGW(event) {
      this.$root.$emit("bv::hide::modal", "modal-create-gw");
      event.preventDefault();
      var accessToken = await this.$auth.getTokenSilently();
      this.$axios
        .post(
          "/v1/accounts/" +
            store.state.accountid +
            "/hubs/" +
            this.gwForm.gateway_id,
          this.gwForm
        )
        .then(() => {
          //Reload from API
          router.push({
            name: "gateways"
          });
        })
        .catch(err => {
          this.$notify({
            title: "Error " + (err.response.data.message || ""),
            type: "error"
          });
        });
    }
  },

  created() {
    bus.$on("switched-account", () => {
      this.getData();
    });
    this.getData();
  }
};
</script>
