<template>
  <b-nav-item-dropdown>
    <template slot="button-content">
      <i class="bx bx-user-circle"></i> {{ $store.state.user.email }}
    </template>

    <b-dropdown-item
      v-if="$store.state.accountList.length > 0"
      :to="{ name: 'userprofile' }"
    >
      User Profile
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item v-on:click="logout()">
      Logout
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
};
</script>

<style></style>
