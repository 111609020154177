<template>
  <b-container>
    <b-breadcrumb>
      <b-breadcrumb-item href="#home">
        <router-link :to="{ name: 'root' }">Home </router-link>
      </b-breadcrumb-item>

      <b-breadcrumb-item>
        <router-link :to="{ name: 'accountsettings' }">Account</router-link>
      </b-breadcrumb-item>
    </b-breadcrumb>
    <b-modal id="modal-4" @hidden="onReset" title="Edit Account" hide-footer>
      <b-form @submit="updateAccount" @reset="onReset">
        <b-form-group
          size="sm"
          id="input-group-1"
          label="Account Name"
          label-for="input-1"
          description=""
        >
          <b-form-input
            size="sm"
            name="name"
            id="input-1"
            v-model="accountForm.name"
            type="text"
          >
          </b-form-input>
        </b-form-group>
        <div class="text-right">
          <b-button type="reset">Cancel</b-button>
          &nbsp;
          <b-button type="submit" variant="primary">Save</b-button>
        </div>
      </b-form>
    </b-modal>

    <div>
      <b-card-group deck>
        <b-card header-tag="header" class="mb-2">
          <template #header>
            <h3 class="mb-0">
              Account

              <b-button
                size="sm"
                variant="primary"
                v-b-modal.modal-4
                class="float-right"
                :title="
                  $store.state.accountLevel > 20
                    ? 'Insufficent permissions'
                    : ''
                "
                :disabled="$store.state.accountLevel > 20"
                >Edit
              </b-button>
            </h3>
          </template>
          <b-card-text>
            <h2>{{ account.name || "Not Set" }}</h2>
            <p class="text-muted">Account ID: {{ account.id || "Not Set" }}</p>
            <p class="text-muted">Your role: {{ accountLevel }}</p>
            <p class="text-muted">
              Subscription Status:
              {{
                account.subscription_status.status == "active"
                  ? "Active."
                  : "Free Trial."
              }}

              <span v-if="account.subscription_status.status == 'active'">
                Manage your subscription and view invoices at the
                <a href="https://billing.blecon.net/p/login/bIYg1Jh0j8ok6Gs6oo"
                  >Blecon Billing Portal</a
                >.
              </span>
            </p>
          </b-card-text>
        </b-card>

        <p v-if="account.default">
          This is your default account and you are the owner.
        </p>
      </b-card-group>
    </div>

    <b-nav tabs class="mt-3">
      <b-nav-item
        exact
        :to="{
          name: 'account_members'
        }"
        >Members
      </b-nav-item>
      <b-nav-item
        :to="{
          name: 'account_invites'
        }"
        >Invites
      </b-nav-item>
      <b-nav-item
        :to="{
          name: 'account_apitokens'
        }"
      >
        API Tokens
      </b-nav-item>
      <b-nav-item
        :to="{
          name: 'account_logs'
        }"
      >
        Account Log
      </b-nav-item>
    </b-nav>

    <router-view></router-view>
  </b-container>
</template>

<script>
import { bus } from "../../main";
import store from "@/store";

export default {
  computed: {
    accountLevel() {
      switch (this.$store.state.accountLevel) {
        case 0:
          return "Owner";
        case 20:
          return "Admin";
        case 30:
          return "Member";
        case 40:
          return "Installer";
      }
      return "";
    }
  },
  data() {
    return {
      accountForm: {
        name: ""
      },
      account: {}
    };
  },

  methods: {
    async getData() {
      store.commit("spin");

      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      await this.$axios
        .get("/accounts/" + this.$store.state.accountid)
        .then(response => {
          this.account = response.data;
          this.accountForm = response.data;
        });
      store.commit("nospin");
    },
    onReset() {
      this.$root.$emit("bv::hide::modal", "modal-4");
      this.getData();
    },
    async updateAccount(event) {
      this.$root.$emit("bv::hide::modal", "modal-4");
      event.preventDefault();
      var accessToken = await this.$auth.getTokenSilently();
      this.$axios
        .post("/accounts/" + this.$store.state.accountid, this.accountForm)
        .then(() => {
          //Reload from API
          bus.$emit("reload-accounts");
          this.getData();
        });
    }
  },

  created() {
    bus.$on("switched-account", () => {
      this.getData();
    });
    this.getData();
  }
};
</script>
