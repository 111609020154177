<template>
  <b-container>
    <div class="b-main-content">
      <b-card>
        <template #header>
          <h3 class="mb-0">
            Devices
          </h3>
        </template>
        <b-table striped hover small :fields="fields" :items="items">
          <template #cell(device_id)="data">
            <router-link
              :to="{
                name: 'viewdevice',
                params: { devId: data.item.device_id }
              }"
              >{{ data.item.device_id }}</router-link
            >
          </template>
        </b-table>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import store from "@/store";

export default {
  data() {
    return {
      fields: [
        { key: "device_id", label: "Device ID" },
        { key: "network_id", label: "Network" },
        { key: "model", label: "Model" },
        { key: "last_updated", label: "Updated", formatter: "formatDate" },
        { key: "created", label: "Created", formatter: "formatDate" }
      ],

      items: []
    };
  },

  methods: {
    formatDate(value) {
      var date = new Date(parseInt(value) * 1000);
      const formattedDate = formatDistance(date, new Date(), {
        addSuffix: true
      });
      return formattedDate;
    },
    async getData() {
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());

      this.$axios
        .get("/v1/accounts/" + store.state.accountid + "/devices")
        .then(response => {
          this.items = response.data.devices;
        });
    }
  },
  created() {
    this.getData();
  }
};
</script>
