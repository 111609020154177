<template>
  <div>
    <b-card class="mt-3" flush>
      <template #header>
        <h5 class="mb-0">Installed Integrations</h5>
      </template>

      <b-table :items="installed_integrations" :fields="fields">
        <template #cell(name)="data">
          {{ data.item.name || data.item.integration_type }}
        </template>
        <template #cell(description)="data">
          <b-link target="_docs" :href="data.item.docs_url">{{
            data.item.title
          }}</b-link
          >: {{ data.item.description || "Memfault legacy integration" }}
        </template>

        <template #cell(actions)="data">
          <b-button
            size="sm"
            variant="primary "
            @click="openModal(data.item)"
            :disabled="data.item.integration_type == 'blecon-util'"
          >
            Configure
          </b-button>

          <b-button
            :disabled="data.item.integration_type == 'blecon-util'"
            class="ml-3"
            size="sm "
            variant="danger"
            @click="remove(data.item)"
          >
            Remove
          </b-button>
        </template>
      </b-table>
      <div v-if="installed_integrations.length == 0">
        <p>No integrations installed</p>
      </div>

      <component
        v-if="current_modal"
        :is="current_modal"
        :integration="selected_integration"
        :config="selected_integration.config"
        :installedIntegrations="installed_integrations"
        :networkConfig="networkConfig"
        @save="saveIntegration"
        @close="closeModal"
      />
    </b-card>

    <b-card class="mt-3" flush>
      <template #header>
        <h5 class="mb-0">Add Integrations</h5>
      </template>

      <b-card-group columns>
        <b-card
          v-for="integration in all_integrations"
          :key="integration.integration_type"
          footer-tag="footer"
        >
          <template #header>
            <div style="height: 1.5em" class="mb-3">
              <img :src="integration.logo_url" height="40" />
            </div>
          </template>
          <b-card-text style="height: 9em">
            <p>
              <b-link target="_docs" :href="integration.docs_url"
                >{{ integration.description }}
              </b-link>
            </p>
            <p>
              <b-button variant="primary" @click="openModal(integration)"
                >Add</b-button
              >
            </p>
          </b-card-text>
        </b-card>
      </b-card-group>
    </b-card>
  </div>
</template>

<script>
//import shared from '@/components/Shared'
import router from "@/router";
import store from "@/store";
import { bus } from "../../main";
import MemfaultModal from "./Integrations/NetworkMemfault.vue";
import WebhookModal from "./Integrations/Webhook.vue";
import AwsS3Modal from "./Integrations/AwsS3.vue";
import AwsEventBridgeModal from "./Integrations/AWSEventBridge.vue";
import AwsSQSModal from "./Integrations/AwsSQS.vue";

export default {
  components: {
    MemfaultModal,
    WebhookModal,
    AwsS3Modal,
    AwsEventBridgeModal,
    AwsSQSModal
  },

  data() {
    return {
      fields: [
        { key: "name", label: "Name " },
        { key: "description", label: "Integration" },
        { key: "actions", label: "Actions" }
      ],

      // add default integration
      installed_integrations: [],
      selected_integration: null,
      current_modal: null,
      networkConfig: null,
      all_integrations: [
        {
          integration_type: "webhook",
          component: "WebhookModal",
          title: "Webhook",
          docs_url: "https://developer.blecon.net/integrations/webhook",
          description: "Send events to a custom URL via HTTPS.",
          logo_url: "/webhook-logo.png",
          name: "Webhook",

          config: {
            url: "",
            capture_device_namespace: "*",
            capture_device: false,
            capture_network_all: false,
            headers_key_1: "",
            headers_val_1: "",
            headers_key_2: "",
            headers_val_2: "",
            headers_key_3: "",
            headers_val_3: ""
          }
        },

        {
          integration_type: "network-memfault",
          component: "MemfaultModal",
          title: "Memfault",
          docs_url: "https://developer.blecon.net/integrations/memfault",
          description: "The Memfault embedded observability platform.",
          logo_url: "/memfault-logo.png",
          name: "Memfault",
          config: {
            namespace: "memfault",
            secret_memfault_key: ""
          }
        },
        {
          integration_type: "aws-s3",
          component: "AwsS3Modal",
          title: "AWS S3",
          docs_url: "https://developer.blecon.net/integrations/aws-s3",
          description: "Securely upload to and download from an AWS S3 bucket.",
          logo_url: "/aws-s3-logo.png",
          name: "AWS S3",
          config: {
            namespace: "aws-s3",
            role_arn: "",
            bucket_name: "",
            region: ""
          }
        },
        {
          integration_type: "aws-eventbridge",
          component: "AwsEventBridgeModal",
          title: "AWS EventBridge",
          docs_url: "https://developer.blecon.net/integrations/aws-eventbridge",
          description: "Send events to AWS EventBridge.",
          logo_url: "/aws-eventbridge-logo.png",
          name: "AWS EventBridge",
          config: {
            namespace: "aws-eventbridge",
            role_arn: "",
            event_bus_name: "",
            region: ""
          }
        },
        {
          integration_type: "aws-sqs",
          component: "AwsSQSModal",
          title: "AWS SQS",
          docs_url: "https://developer.blecon.net/integrations/aws-sqs",
          description: "Send events to AWS SQS",
          logo_url: "/aws-sqs-logo.png",
          name: "AWS SQS",

          config: {
            namespace: "aws-sqs",
            role_arn: "",
            sqs_url: "",
            region: ""
          }
        }
      ]
    };
  },

  methods: {
    cleanUpRoutes(response, integration) {
      var routes = [];
      this.$axios
        .get(
          "/v1/accounts/" +
            store.state.accountid +
            "/networks/" +
            this.$route.params.networkId
        )

        .then(response => {
          // if response routes is empty, return
          this.$log.debug(
            "network config before removal: " + JSON.stringify(response.data)
          );

          const filteredRoutes = response.data.routes.filter(
            route =>
              route.headers?.["blecon-integration-config-id"] &&
              route.headers["blecon-integration-config-id"] !==
                integration.config_id
          );
          this.$log.debug("filtered routes: " + JSON.stringify(filteredRoutes));

          this.$axios
            .post(
              "/v1/accounts/" +
                store.state.accountid +
                "/networks/" +
                this.$route.params.networkId,
              { routes: filteredRoutes }
            )
            .then(response => {
              this.$log.debug("Removed route");
              bus.$emit("integrations-reload");
              this.$notify({
                title: "Integration removed",
                type: "success"
              });
            });
        })
        .catch(err => {
          this.$log.error("Error removing route: " + err);
          bus.$emit("integrations-reload");
          this.$notify({
            title: "Integration route not removed",
            type: "error"
          });
        });
    },

    remove(integration) {
      this.$confirm(
        "Are you sure you want to remove this integration? \
        The configuration will be deleted and requests for this namespace will no longer be handled."
      )
        .then(() => {
          this.$log.debug("Removing integration: " + integration.config_id);
          this.$store.commit("spin");
          this.$axios
            .delete(
              "/v1/accounts/" +
                store.state.accountid +
                "/networks/" +
                this.$route.params.networkId +
                "/integrations/" +
                integration.config_id
            )
            .then(response => {
              //get all network routes and delete ones where the config_id is in the headers under blecon-integration-config-id
              this.cleanUpRoutes(response, integration);
              this.$store.commit("nospin");
            });
        })
        .catch(() => {
          this.$notify({
            title: "Cancelled",
            type: "info"
          });
        });
    },

    async openModal(integration) {
      this.$log.debug(
        "Opening modal for integration: " + JSON.stringify(integration)
      );
      await this.getNetworkConfig();

      this.selected_integration = integration;

      //remove the routes with the config_id of selected_integration, if selected_integration has a config_id
      //this is required for the integration to be able to calculate the new route map
      if (this.selected_integration.config_id) {
        var routes = this.networkConfig.routes.filter(
          route =>
            !(
              route.headers?.["blecon-integration-config-id"] &&
              route.headers["blecon-integration-config-id"] ===
                this.selected_integration.config_id
            )
        );
      } else {
        routes = this.networkConfig.routes;
      }
      this.networkConfig.routes = routes;
      this.current_modal = integration.component;
    },

    async getNetworkConfig() {
      const networkConfigResponse = await this.$axios.get(
        "/v1/accounts/" +
          store.state.accountid +
          "/networks/" +
          this.$route.params.networkId
      );

      this.networkConfig = networkConfigResponse.data;
      this.$log.debug("Network config: " + this.networkConfig);
    },

    async saveIntegration(newConfig, name, routes) {
      this.$log.debug(
        "saving config: " +
          JSON.stringify(newConfig) +
          " for integration: " +
          this.selected_integration.integration_type +
          " with name: " +
          name +
          " and routes: " +
          JSON.stringify(routes)
      );

      const integrationConfigObject = {
        type: this.selected_integration.integration_type,
        config: newConfig,
        name: name
      };

      this.$log.debug(
        "integrationConfigObject: " + JSON.stringify(integrationConfigObject)
      );

      // Construct the first URL
      if (this.selected_integration.config_id) {
        var intAPIUrl =
          "/v1/accounts/" +
          store.state.accountid +
          "/networks/" +
          this.$route.params.networkId +
          "/integrations/" +
          this.selected_integration.config_id;
      } else {
        intAPIUrl =
          "/v1/accounts/" +
          store.state.accountid +
          "/networks/" +
          this.$route.params.networkId +
          "/integrations";
      }

      try {
        const saveConfigResponse = await this.$axios.post(
          intAPIUrl,
          integrationConfigObject
        );

        this.$log.debug(
          "Updated/Saved integration: " + saveConfigResponse.data
        );

        this.selected_integration = saveConfigResponse.data;

        //get routes from networkConfig which do not have this config_id in the headers
        this.$log.debug(
          "Filtering routes for config_id: " +
            this.selected_integration.config_id
        );
        var networkRoutes = this.networkConfig.routes.filter(
          route =>
            !(
              route.headers?.["blecon-integration-config-id"] &&
              route.headers["blecon-integration-config-id"] ===
                this.selected_integration.config_id
            )
        );

        // for each new route, extend the headers to add the config id
        routes.forEach(route => {
          route.headers = {
            ...route.headers,
            "blecon-integration-config-id": this.selected_integration.config_id,
            "blecon-integration-type": this.selected_integration
              .integration_type
          };
        });

        //merge the new routes with the existing routes
        var combinedRoutes = routes.concat(networkRoutes);

        this.$log.debug("Combined routes: " + JSON.stringify(combinedRoutes));
        const networkConfig = {
          routes: combinedRoutes
        };

        // update the network config
        const networkConfigResponse = await this.$axios.post(
          "/v1/accounts/" +
            store.state.accountid +
            "/networks/" +
            this.$route.params.networkId,
          networkConfig
        );

        this.networkConfig = networkConfigResponse.data;
        this.$log.debug("Updated network: " + networkConfigResponse.data);
      } catch (error) {
        this.$log.error("Error saving integration: " + error);
        this.$notify({
          title: "Error saving integration",
          type: "error"
        });
        bus.$emit("integrations-reload");
        return;
      }

      this.$notify({
        title: "Integration saved",
        type: "success"
      });

      bus.$emit("integrations-reload");
      this.closeModal();
    },

    closeModal() {
      this.current_modal = null;
    },

    async getIntegrations() {
      var accessToken = await this.$auth.getTokenSilently();
      this.$axios
        .get(
          "/v1/accounts/" +
            store.state.accountid +
            "/networks/" +
            this.$route.params.networkId +
            "/integrations"
        )
        .catch(err => {
          router.push("/404");
        })
        .then(response => {
          this.$store.commit("spin");

          //if (!response) return; // `response` is undefined

          this.installed_integrations = [
            {
              integration_type: "blecon-util",
              component: null,
              title: "Blecon Utility",
              name: "Blecon Utility",
              description: "Utility integration for Blecon",
              docs_url: "https://developer.blecon.net/integrations/blecon-util",
              config: {
                namespace: "global:blecon-util"
              }
            }
          ];

          // extend it with response.data

          this.installed_integrations = this.installed_integrations.concat(
            response.data.integrations
          );

          // expand it with matching entries from all_integrations keyed on integration_type
          this.installed_integrations.forEach(
            function(item) {
              var found = this.all_integrations.find(
                function(element) {
                  return element.integration_type === item.integration_type;
                }.bind(this)
              );
              if (found) {
                // if an item doesn't exist in the integraiton, add it from the template

                item.component = found.component;
                item.description = found.description;
                item.docs_url = found.docs_url;
                item.title = found.title;
                item.url = found.url;
              }
            }.bind(this)
          );

          this.$store.commit("nospin");
        });
    }
  },

  mounted() {
    this.$store.commit("spin");
    this.getIntegrations();
    bus.$on("integrations-reload", () => {
      this.$store.commit("spin");

      this.getIntegrations();
      this.$store.commit("nospin");
    });
    this.$store.commit("nospin");
  }
};
</script>
