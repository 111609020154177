<template>
  <div>
    <b-modal
      id="modal-delete"
      title="Are you sure you want to delete this Network?"
      hide-footer
    >
      <b-button class="float-right" variant="danger" @click="deleteApp"
        >Delete</b-button
      >
    </b-modal>
    <b-modal
      id="modal-landing"
      title="Set Device Landing Page URL"
      hide-footer
      @hidden="onReset"
    >
      <b-form @submit="onSubmit" @reset="onReset">
        <b-form-group
          size="sm"
          id="input-group-1"
          label="Device Landing Page"
          label-for="input-1"
          description="Provide a custom Device Landing Page URL if required. The Device ID is provided as a URL parameter."
        >
          <b-form-input
            width="100%"
            id="uuidInputField"
            v-model.trim="$v.value.form.device_landing_page.$model"
          ></b-form-input>
        </b-form-group>
        <div class="form-error" v-if="$v.value.form.device_landing_page.$error">
          Valid URL required
        </div>
        <div class="float-right mt-3">
          <b-button type="reset">Cancel</b-button>
          &nbsp;
          <b-button
            type="submit"
            :disabled="$v.value.form.device_landing_page.$error"
            variant="primary"
            >Save</b-button
          >
        </div>
      </b-form>
    </b-modal>

    <b-modal id="modal-net" title="Edit Network" hide-footer @hidden="onReset">
      <b-form @submit="onSubmit" @reset="onReset">
        <b-form-group
          size="sm"
          id="input-group-1"
          label="Name"
          label-for="input-1"
          description="Network Name"
        >
          <b-form-input
            size="sm"
            id="input-1"
            v-model="$v.value.form.name.$model"
            type="text"
          >
          </b-form-input>
        </b-form-group>
        <div class="form-error" v-if="$v.value.form.name.$error">
          Valid name required
        </div>
        <div class="float-right">
          <b-button type="reset">Cancel</b-button>
          &nbsp;
          <b-button
            type="submit"
            :disabled="$v.value.form.name.$error"
            variant="primary"
            >Save</b-button
          >
        </div>
      </b-form>
    </b-modal>
    <b-card class="mt-3" flush>
      <template #header>
        <h5 class="mb-0">Network Settings</h5>
      </template>
      <b-list-group flush>
        <b-list-group-item>
          <h5>Network Name</h5>
          <b-button
            class="mt-3 float-right"
            v-b-modal.modal-net
            id="modal-net"
            variant="primary"
            >Edit</b-button
          >
          <br />
          <code> {{ this.form.name || "Not Set" }} </code>
        </b-list-group-item>

        <b-list-group-item>
          <h5>Device Landing Page</h5>
          <b-button
            class="mt-3 float-right"
            v-b-modal.modal-landing
            id="modal-landing"
            variant="primary"
            >Edit</b-button
          >

          <p>
            The device landing page is used when a registered device is scanned.
            By default, this page shows device details in the Blecon Console.
          </p>

          <code>{{
            this.form.device_landing_page || "Default console page"
          }}</code>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <b-card class="mt-3">
      <h5 class="text-danger mb-0">
        Danger Zone
        <b-button
          class="float-right"
          v-b-modal.modal-delete
          variant="danger"
          size="sm"
          >Delete Network</b-button
        >
      </h5>
    </b-card>
  </div>
</template>

<script>
//import shared from '@/components/Shared'
import router from "@/router";
import store from "@/store";
import { bus } from "../../main";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers, url } from "@vuelidate/validators";

import { format, formatDistance, formatRelative, subDays } from "date-fns";

export default {
  validations() {
    return {
      form: {
        name: { required },
        device_landing_page: { url }
      }
    };
  },
  data() {
    return {
      fields: [
        { key: "device_id", label: "Device ID" },
        { key: "model", label: "Model" },
        { key: "last_seen", label: "Last Seen", formatter: "formatDate" },
        { key: "description", label: "Description" }
      ],
      api: {},
      devices: [],
      logpeek: [],
      form: {
        name: "",
        device_landing_page: ""
      }
    };
  },
  created() {
    this.$v = useVuelidate(this.validations, this);

    this.loadData();
  },
  methods: {
    formatDate(value) {
      if (value) {
        var date = new Date(parseInt(value) * 1000);
        const formattedDate = formatDistance(date, new Date(), {
          addSuffix: true
        });
        return formattedDate;
      }
    },
    async loadData() {
      var accessToken = await this.$auth.getTokenSilently();

      this.$axios
        .get(
          "/v1/accounts/" +
            store.state.accountid +
            "/networks/" +
            this.$route.params.networkId
        )
        .catch(err => {
          router.push("/404");
        })
        .then(response => {
          if (!response) return; // `response` is undefined

          this.api = response.data;
          this.form.name = response.data.name;
          this.form.device_landing_page = response.data.device_landing_page;
          var udate = new Date(parseInt(this.api.last_updated) * 1000);
          this.api.last_updated = formatDistance(udate, new Date(), {
            addSuffix: true
          });
          var date = new Date(parseInt(this.api.created) * 1000);
          this.api.created = formatDistance(date, new Date(), {
            addSuffix: true
          });
        });
    },
    async deleteApp() {
      this.$root.$emit("bv::hide::modal", "modal-1");
      this.$root.$emit("bv::hide::modal", "modal-delete");

      var accessToken = await this.$auth.getTokenSilently();

      this.$axios
        .delete(
          "/v1/accounts/" +
            store.state.accountid +
            "/networks/" +
            this.$route.params.networkId
        )
        .then(() => {
          this.$notify({
            title: "Network deleted",
            type: "success"
          });

          router.push({
            name: "root",
            params: {
              account: this.$store.state.accountid
            }
          });
        })
        .catch(err => {
          this.$notify({
            title: "Error: " + (err.response.data.message || ""),
            type: "error"
          });
        });
    },

    async onSubmit(event) {
      this.$root.$emit("bv::hide::modal", "modal-net");
      this.$root.$emit("bv::hide::modal", "modal-landing");

      event.preventDefault();
      var accessToken = await this.$auth.getTokenSilently();
      this.$axios
        .post(
          "/v1/accounts/" +
            store.state.accountid +
            "/networks/" +
            this.$route.params.networkId,
          this.form
        )
        .then(() => {
          //Reload from API
          this.$notify({
            title: "Network saved",
            type: "success"
          });
          this.$root.$emit("bv::hide::modal", "modal-net");
          this.$root.$emit("bv::hide::modal", "modal-landing");

          this.loadData();
          bus.$emit("net-reload", "net-reload");
        });
    },
    onReset(event) {
      this.$root.$emit("bv::hide::modal", "modal-net");
      this.$root.$emit("bv::hide::modal", "modal-landing");

      event.preventDefault();
      // Reset our form values to what we last got from the API
      Object.assign(this.form, this.api);
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>
