<template>
  <b-container>
    <b-breadcrumb>
      <b-breadcrumb-item href="#home">
        <router-link :to="{ name: 'root' }">Home </router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item href="#">{{ api.name }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-modal id="modal-qr" title="Blecon Scanner" hide-footer>
      <b-img
        width="150"
        :src="
          'https://qr.blecon.net/qr/?url=https://blecon.app/' +
            $route.params.networkId
        "
        fluid
      ></b-img>
      <p>
        <br />
        Scan with your mobile phone to start adding a device.
      </p>
      <p>
        Or, use the Network Link:
        <a :href="'https://blecon.app/' + $route.params.networkId" target="_new"
          >https://blecon.app/{{ $route.params.networkId }}</a
        >
      </p>
    </b-modal>

    <div>
      <b-card-group deck>
        <b-card header="Network" header-tag="header">
          <template #header>
            <h3 class="mb-0">
              Network Information
            </h3>
          </template>
          <h5>
            {{ this.api.name || "Not Set" }}
          </h5>
          <div class="text-muted">{{ $route.params.networkId }}</div>
          <div>Created {{ this.api.created || "Not Set" }}</div>
          <div>Updated {{ this.api.last_updated || "Not Set" }}</div>
        </b-card>
        <b-card header="Register Device" header-tag="header">
          <template #header>
            <h3 class="mb-0">
              Register Device
            </h3>
          </template>

          <b-form @submit="registerDevice">
            <b-form-group
              id="uuidInput"
              label="Device ID"
              label-for="uuidInputField"
            >
              <b-row>
                <b-col cols="11">
                  <b-input-group>
                    <b-form-input
                      width="100%"
                      class="form-control-sm"
                      id="uuidInputField"
                      v-model="$v.value.deviceID.$model"
                    ></b-form-input>

                    <b-button
                      variant="secondary"
                      size="sm"
                      target="scanner"
                      :href="'https://blecon.app/scanner?callback=' + callback"
                      >Use Scanner</b-button
                    >
                  </b-input-group>

                  <div class="form-error" v-if="$v.value.deviceID.$error">
                    Valid Device ID required
                  </div>
                </b-col>
              </b-row>
              <br />

              <b-button
                type="submit"
                variant="primary"
                :disabled="$v.value.deviceID.$error"
                >Register Device</b-button
              >

              <a
                target="docs"
                style="float: right"
                class="text-muted small mt-4"
                href="https://docs.blecon.net/learn-blecon/registering-devices"
                ><i class="bx bx-book-open"></i> Learn more about the scanner
                <br />and registering devices</a
              >
            </b-form-group>
          </b-form>
        </b-card>
      </b-card-group>
    </div>
    <b-nav tabs class="mt-3">
      <b-nav-item
        exact
        :active="this.$route.name == 'net_home'"
        :to="{
          name: 'net_home',
          params: { networkId: this.$route.params.networkId }
        }"
      >
        Events
      </b-nav-item>
      <b-nav-item
        :to="{
          name: 'net_devices',
          params: { networkId: this.$route.params.networkId }
        }"
      >
        Devices
      </b-nav-item>

      <b-nav-item
        :to="{
          name: 'net_integrations',
          params: { networkId: this.$route.params.networkId }
        }"
        >Integrations</b-nav-item
      >
      <b-nav-item
        v-if="this.created_orignal < 1733409781"
        :to="{
          name: 'net_routes',
          params: { networkId: this.$route.params.networkId }
        }"
      >
        Event Routes
      </b-nav-item>
      <b-nav-item
        :to="{
          name: 'net_settings',
          params: { networkId: this.$route.params.networkId }
        }"
        >Network Settings</b-nav-item
      >
    </b-nav>

    <router-view :netinfo="api"></router-view>
  </b-container>
</template>

<script>
//import shared from '@/components/Shared'
import router from "@/router";
import store from "@/store";
import { bus } from "../../main";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
const uuidRegex = helpers.regex(
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
);
import { format, formatDistance, formatRelative, subDays } from "date-fns";

export default {
  mounted() {
    if (this.$route.query.device_id) {
      // Set the value of 'name' from query param
      this.$v.value.deviceID.$model = this.$route.query.device_id;
    }
  },
  components: {},

  data() {
    const callback = encodeURIComponent(
      "https://console.blecon.net/networks/" + this.$route.params.networkId
    );
    return {
      api: {},
      deviceID: "",
      callback: callback
    };
  },
  validations() {
    return {
      deviceID: { required, uuidRegex } // Matches this.firstName
    };
  },
  created() {
    this.$v = useVuelidate(this.validations, this);

    this.loadData();
    bus.$on("net-reload", () => {
      this.loadData();
    });
  },
  methods: {
    async registerDevice(ev) {
      this.$log.debug("registering device");
      // register a device from a GET request
      ev.preventDefault();

      //check to see if it's registered already
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      this.$axios
        .get(
          "/v1/accounts/" + store.state.accountid + "/devices/" + this.deviceID
        )
        .then(response => {
          this.$notify({
            title: "This device is already registered to your account.",
            type: "success"
          });
          router.push({
            name: "viewdevice",
            params: {
              account: this.$store.state.accountid,
              devId: this.deviceID
            }
          });
          return {};
        })
        .catch(err => {
          // 404 means we should try and register
          this.$log.debug(err.response.data.message);
          this.$axios
            .post("/v1/accounts/" + store.state.accountid + "/devices", {
              device_id: this.deviceID,
              network_id: this.$route.params.networkId
            })
            .then(response => {
              this.$notify({
                title: "Device Added",
                type: "success"
              });

              router.push({
                name: "viewdevice",
                params: {
                  account: this.$store.state.accountid,
                  devId: this.deviceID
                }
              });
            })

            .catch(err => {
              //malformed request
              this.$notify({
                title:
                  "Error while Adding Device " +
                  (err.response.data.message || ""),
                type: "error"
              });
            });
        });
    },

    async loadData() {
      this.$store.commit("spin");

      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      this.$axios
        .get(
          "/v1/accounts/" +
            store.state.accountid +
            "/networks/" +
            this.$route.params.networkId
        )
        .catch(err => {
          router.push("/404");
        })
        .then(response => {
          if (!response) return; // `response` is undefined

          this.api = response.data;
          var udate = new Date(parseInt(this.api.last_updated) * 1000);
          this.api.last_updated = formatDistance(udate, new Date(), {
            addSuffix: true
          });
          var date = new Date(parseInt(this.api.created) * 1000);
          this.created_orignal = this.api.created;
          this.api.created = formatDistance(date, new Date(), {
            addSuffix: true
          });
          this.$store.commit("nospin");
        });
    }
  }
};
</script>
