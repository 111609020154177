<template>
  <div>
    <b-card class="mt-3" flush>
      <template #header>
        <h3 class="mb-0">
          Account Log
        </h3>
      </template>
      <b-card-text>
        <b-table striped hover small :items="items" :fields="fields"> </b-table>
        <p v-if="!items.length">No results</p>
        <b-button
          class="mr-3"
          :disabled="pages.length == 1"
          @click="firstPage()"
          >Latest</b-button
        >
        <b-button class="mr-3" :disabled="pages.length == 1" @click="prevPage()"
          >Newer</b-button
        >

        <b-button class="mr-3" :disabled="!cursor" @click="nextPage()"
          >Older</b-button
        >
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { format, formatDistance, formatRelative, subDays } from "date-fns";

export default {
  data() {
    return {
      time1: new Date().toISOString().split("T")[0],
      items: [],
      cursor: undefined,
      pages: [],
      fields: [
        { key: "timestamp", label: "Timestamp" },
        { key: "object_type", label: "Item" },
        { key: "code", label: "Action" },

        { key: "object", label: "Item ID" },
        { key: "username", label: "Actor" }
      ]
    };
  },

  methods: {
    nextPage() {
      this.$log.debug("nextpage");

      this.getData();
    },
    prevPage() {
      this.$log.debug("prevpage");

      if (this.pages.length <= 2) {
        return this.firstPage();
      } else {
        this.cursor = this.pages.pop();
        this.cursor = this.pages.pop();
      }
      this.getData();
    },
    firstPage() {
      this.$log.debug("firstPage");
      this.cursor = undefined;
      this.pages = [];
      this.getData();
    },
    changeDate() {
      this.$log.debug("changeDate");

      this.$log.debug(this.time1);
      this.getData();
      this.cursor = undefined;
      this.pages = [];
    },
    formatDate(value) {
      var date = new Date(parseInt(value) * 1000);
      const formattedDate = formatDistance(date, new Date(), {
        addSuffix: true
      });
      return formattedDate;
    },
    async getData() {
      //this.$store.commit("spin");

      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());

      var p = { pagesize: 30 };
      if (this.cursor) {
        p.cursor = this.cursor;
      }
      await this.$axios
        .get("/v1/accounts/" + this.$store.state.accountid + "/log", {
          params: p
        })
        .then(response => {
          this.$log.debug(response.data.cursor);
          this.items = response.data.records;
          this.cursor = response.data.cursor;

          if (response.data.cursor != undefined) {
            this.cursor = response.data.cursor;
            this.pages.push(this.cursor);
            this.$store.commit("nospin");
          }
        });
    }
  },
  async created() {
    this.$log.debug("created");
    await this.getData();
  }
};
</script>
