<template>
  <div class="wrapper">
    <div class="bg-dark">
      <div class="container p-0" style="position:relative">
        <div
          class="pt-3  text-center "
          style="position: absolute; z-index: 99; top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);"
        >
          <router-link :to="{ name: 'root' }"
            ><img src="/blecon-logo.png" height="24"
          /></router-link>
        </div>
        <b-navbar toggleable="md" type="dark" variant="dark" class="p-2">
          <b-navbar-toggle width="59" target="nav-collapse-accounts"
            ><i class="bx bx-sitemap"></i> / <i class="bx bx-user-circle"></i
          ></b-navbar-toggle>
          <b-collapse id="nav-collapse-accounts" class="text-right" is-nav>
            <b-navbar-nav>
              <account-dropdown></account-dropdown>
            </b-navbar-nav>

            <b-navbar-brand class="d-none d-md-inline mx-auto text-center">
            </b-navbar-brand>

            <b-navbar-nav>
              <console-navbar-user></console-navbar-user>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </div>
    <div class="bg-light border-bottom mb-2">
      <div class="container p-0"></div>
    </div>
  </div>
</template>

<style>
.form-error {
  color: rgb(214, 123, 12);
  font-size: 80%;
  font-weight: 400;
  font-family: Montserrat, sans-serif;
}

p.console {
  font-family: "Roboto Mono", monospace;
  margin: 1px;
  font-size: 0.7em;
}

.spotted {
  padding: 0px 4px;
  display: inline-block;
  border-radius: 4px;
  background-color: rgb(40, 167, 69);
}

.request {
  padding: 0px 4px;
  display: inline-block;
  border-radius: 4px;
  background-color: rgb(0, 123, 255);
}

.error {
  padding: 0px 4px;
  display: inline-block;
  border-radius: 4px;
  background-color: rgb(220, 53, 69);
}

.warning {
  padding: 0px 4px;
  display: inline-block;
  border-radius: 4px;
  background-color: rgb(255, 193, 7);
}

.info {
  padding: 0px 4px;
  display: inline-block;
  border-radius: 4px;
  background-color: rgb(108, 117, 125);
}

.terminal-control {
  color: rgb(108, 117, 125);
}

.terminal-filters {
  font-family: "Roboto Mono", monospace;
  color: #fafafa;
  font-size: 0.7em;
}

.terminal-container {
  padding-top: 1em;
  text-align: left;
  width: 100%;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 14px;
  position: relative;
}

.terminal-header {
  background: #e0e8f0;
  border-radius: 8px 8px 0 0;
  padding: 5px;
}

.terminal-body {
  background-color: #30353a;
  padding: 1.5em 1em 1em 1em;
  color: #fafafa;
  height: 10em;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}
</style>

<script>
import { mapState } from "vuex";
import router from "@/router";
import { bus } from "../main";

import AccountDropdown from "./AccountDropdown.vue";
import ConsoleNavbarUser from "./ConsoleNavbarUser.vue";

export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },

  components: {
    AccountDropdown,
    ConsoleNavbarUser
  },
  data() {
    return {
      activeNotifications: false,
      accountFields: ["id", "name", "user_level", "tools"]
    };
  },

  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
};
</script>

<style>
div.bg-dark {
  background-color: #0080ff !important;
}
.navbar-dark.bg-dark {
  background-color: #0080ff !important;
}
.nav-link.dropdown-toggle {
  color: white !important;
}
</style>
