<template>
  <b-container>
    <b-modal
      id="modal-delete-gw"
      @hidden="onReset"
      title="Delete Global Forwarder"
      hide-footer
    >
      <p>Are you sure you want to delete this Global Forwarder?</p>
      <div class="text-right">
        <b-button type="submit" variant="danger" @click="deleteGFW"
          >Delete</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-create-gw"
      @hidden="onReset"
      title="Create Global Forwarder"
      hide-footer
    >
      <b-form @submit="createGW" @reset="onReset">
        <b-form-group
          size="sm"
          id="input-group-1"
          label="Namespace"
          label-for="input-1"
          description="Namespace. Must be globally unique and begin with 'global:'"
        >
          <b-form-input
            size="sm"
            name="namespace"
            id="input-1"
            v-model="gfwForm.namespace"
            type="text"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          size="sm"
          id="input-group-1"
          label="Device Filter"
          label-for="input-1"
          description="Device ID or * for all devices"
        >
          <b-form-input
            size="sm"
            name="device_filter"
            id="input-1"
            v-model="gfwForm.device_filter"
            type="text"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          size="sm"
          id="input-group-2"
          label="Destination Network"
          label-for="input-2"
          description="Destination Network ID"
        >
          <b-form-input
            size="sm"
            name="name"
            id="input-2"
            v-model="gfwForm.destination_network"
            type="text"
          >
          </b-form-input>
        </b-form-group>

        <b-form-checkbox
          id="internal_only"
          v-model="gfwForm.internal_only"
          name="internal_only"
          value="true"
          unchecked-value="false"
          >Internal Only</b-form-checkbox
        >

        <div class="text-right">
          <b-button type="submit" variant="primary">Create</b-button>
        </div>
      </b-form>
    </b-modal>

    <div class="b-main-content">
      <b-breadcrumb>
        <b-breadcrumb-item href="#home">
          <router-link :to="{ name: 'root' }">Home </router-link>
        </b-breadcrumb-item>

        <b-breadcrumb-item>
          <router-link :to="{ name: 'global_forwarders' }"
            >Global Forwarders</router-link
          >
        </b-breadcrumb-item>
      </b-breadcrumb>

      <b-card>
        <template #header>
          <h3 class="mb-0">
            Global Forwarders
          </h3>
        </template>
        <b-button
          v-b-modal.modal-create-gw
          variant="primary"
          class="mb-4"
          size="sm"
          style="float: right;"
          >Create Global Forwarder</b-button
        >

        <b-table
          striped
          hover
          small
          :fields="fields"
          :items="this.globalForwarders"
        >
          <template #cell(actions)="data">
            <b-button
              size="sm"
              class="ml-3"
              v-b-modal.modal-delete-gw
              @click="
                selectItem(
                  data.item.namespace,
                  data.item.device_filter,
                  data.item.domain
                )
              "
              variant="danger"
              >Delete</b-button
            >
          </template>
        </b-table>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import { Store } from "vuex";
import { bus } from "../main";

export default {
  components: {},
  computed: {
    accountLevel() {
      switch (this.$store.state.accountLevel) {
        case 0:
          return "Owner";
        case 20:
          return "Admin";
        case 30:
          return "Member";
        case 40:
          return "Installer";
      }
      return "";
    }
  },
  data() {
    return {
      fields: [
        { key: "namespace", label: "Namespace" },
        { key: "domain", label: "Domain" },

        { key: "device_filter", label: "Device Filter" },

        { key: "destination_network", label: "Destination" },
        { key: "actions", label: "Actions" }
      ],
      gfwForm: {
        namespace: "",
        destination_network: "",
        device_filter: "",
        internal_only: false
      },
      globalForwarders: [],
      selectedNamespace: "",
      selectedDeviceFilter: ""
    };
  },

  methods: {
    selectItem(namespace, device_filter, domain) {
      this.selectedNamespace = namespace;
      this.selectedDeviceFilter = device_filter;
      this.selectedDomain = domain;
    },

    async deleteGFW() {
      this.$root.$emit("bv::hide::modal", "modal-delete-gw");
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      await this.$axios
        .delete(
          "/v1/accounts/" + this.$store.state.accountid + "/global-forwarders",
          {
            params: {
              namespace: this.selectedNamespace,
              device_filter: this.selectedDeviceFilter,
              domain: this.selectedDomain
            }
          }
        )
        .then(() => {
          this.getData();
        });
    },
    async getData() {
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      await this.$axios
        .get(
          "/v1/accounts/" + this.$store.state.accountid + "/global-forwarders"
        )
        .then(response => {
          this.globalForwarders = response.data.records;
        });
    },
    onReset() {
      this.getData();
      self.gfwForm = {
        namespace: "",
        destination_network: "",
        device_filter: ""
      };
    },
    async createGW(event) {
      this.$root.$emit("bv::hide::modal", "modal-create-gw");
      event.preventDefault();
      var accessToken = await this.$auth.getTokenSilently();

      if (this.gfwForm.internal_only == "true") {
        this.gfwForm.internal_only = true;
      } else {
        this.gfwForm.internal_only = false;
      }

      this.$axios
        .post(
          "/v1/accounts/" + this.$store.state.accountid + "/global-forwarders",
          this.gfwForm
        )
        .then(() => {
          //Reload from API
          this.getData();
        })
        .catch(err => {
          this.$notify({
            title: "Error " + (err.response.data.message || ""),
            type: "error"
          });
        })
        .then(() => {
          //Reload from API
          this.getData();
        })
        .catch(err => {
          this.$notify({
            title: "Error " + (err.response.data.message || ""),
            type: "error"
          });
        });
    }
  },

  created() {
    bus.$on("switched-account", () => {
      this.getData();
    });
    this.getData();
  }
};
</script>
