<template>
  <b-container>
    <div class="b-main-content">
      <b-modal id="modal-1" title="Password change" hide-footer>
        We have sent you an email with a link to change your password.
      </b-modal>
      <b-breadcrumb>
        <b-breadcrumb-item href="#home">
          <router-link :to="{ name: 'root' }">Home </router-link>
        </b-breadcrumb-item>

        <b-breadcrumb-item href="#">User Profile</b-breadcrumb-item>
      </b-breadcrumb>
      <b-card header-tag="header" :title="$auth.user.email" class="mb-2">
        <template #header>
          <h3 class="mb-0">
            User Profile<b-button
              v-if="$auth.isAuthenticated"
              @click="logout"
              size="sm"
              variant="primary"
              class="float-right"
              >Logout</b-button
            >
          </h3>
        </template>

        <div v-if="$store.state.user.sub.startsWith('auth0|')">
          <b-button
            v-if="$auth.isAuthenticated"
            @click="chpass"
            size="sm"
            variant="primary"
            >Change Password</b-button
          >
        </div>
      </b-card>
    </div>
  </b-container>
</template>

<script>
const axios = require("axios");
import store from "@/store";

export default {
  methods: {
    chpass() {
      var options = {
        method: "POST",
        url:
          "https://" +
          process.env.VUE_APP_AUTH0_DOMAIN +
          "/dbconnections/change_password",
        headers: { "content-type": "application/json" },
        data: {
          client_id: process.env.VUE_APP_CLIENTID,
          email: this.$auth.user.email,
          connection: "Username-Password-Authentication"
        }
      };

      axios
        .request(options)
        .then(function(response) {})
        .catch(function(error) {});
      this.$root.$emit("bv::show::modal", "modal-1");
    },

    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
};
</script>
