<template>
  <div>
    <b-modal
      id="modal-showsecret"
      @hidden="onReset"
      title="Secret Key"
      hide-footer
      size="lg"
    >
      <p>
        This is your secret API key, which is used to authenticate with the
        Blecon API. Keep it safe - it will not be shown again.
      </p>
      <textarea disabled cols="60" v-model="secret"></textarea>
      <div class="float-right">
        <b-button type="reset" @click="closeSecret">Close</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-newtoken"
      @hidden="onReset"
      title="Create API Token"
      hide-footer
    >
      <p>
        Create an API Token, which can be used to authenticate with the Blecon
        API
      </p>
      <b-form @submit="newToken" @reset="onReset">
        <b-form-group
          size="sm"
          id="input-group-1"
          label="Name for the token"
          label-for="input-1"
          description="This is a friendly name for the token, to help you remember what it's for"
        >
          <b-form-input
            size="sm"
            id="input-1"
            v-model="createForm.name"
            type="text"
          >
          </b-form-input>
        </b-form-group>
        <div class="float-right">
          <b-button type="reset">Cancel</b-button>
          &nbsp;
          <b-button
            type="submit"
            :disabled="$store.state.spin"
            variant="primary"
            >Create Token</b-button
          >
        </div>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-editmember"
      title="Edit Member"
      @hidden="onReset"
      hide-footer
    >
      <b-form @submit="saveEditMember" @reset="onReset">
        <b-form-group
          size="sm"
          id="input-group-1"
          label="User level"
          label-for="input-1"
        >
          <b-form-select
            size="sm"
            v-model="form.user_level"
            :options="options"
          ></b-form-select>
        </b-form-group>
        <b-button type="submit" variant="primary">Save</b-button>
        <b-button type="reset" class="ml-3">Cancel</b-button>

        &nbsp;
      </b-form>
    </b-modal>

    <b-card class="mt-3" flush>
      <template #header>
        <h5 class="mb-0">
          API Tokens
          <b-button
            :title="
              $store.state.accountLevel > 20 ? 'Insufficent permissions' : ''
            "
            :disabled="$store.state.accountLevel > 20"
            size="sm"
            variant="success"
            v-b-modal.modal-newtoken
            class="float-right"
            >Create New</b-button
          >
        </h5>
      </template>

      <b-table striped hover small :items="apiTokens" :fields="memberFields">
        <template #cell(actions)="data">
          <b-button
            :title="
              $store.state.accountLevel > 20 ? 'Insufficent permissions' : ''
            "
            :disabled="$store.state.accountLevel > 20"
            size="sm"
            variant="warning"
            @click="deleteToken(data.item.token_id)"
            >Delete API Token</b-button
          >
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { bus } from "../../main";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import store from "@/store";

export default {
  async created() {
    bus.$on("switched-account", () => {
      this.getData();
    });
    await this.getData();
  },
  methods: {
    async closeSecret() {
      this.$root.$emit("bv::hide::modal", "modal-showsecret");
      await this.getData();
    },
    formatDate(value) {
      if (value) {
        var date = new Date(parseInt(value) * 1000);
        const formattedDate = format(date, "yyyy-MM-dd HH:mm:ss");
        return formattedDate;
      } else {
        return "Never";
      }
    },
    async deleteToken(member) {
      this.$log.debug("Delete member", member);
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());

      await this.$axios
        .delete(
          "/v1/accounts/" +
            this.$store.state.accountid +
            "/api-tokens/" +
            member
        )
        .then(response => {
          this.getData();
        })
        .catch(err => {
          this.$notify({
            title: "Error " + (err.response.data.message || ""),
            type: "error"
          });
        });
    },

    async newToken(event) {
      event.preventDefault();

      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      await this.$axios
        .post(
          "/v1/accounts/" + this.$store.state.accountid + "/api-tokens",
          this.createForm
        )
        .then(response => {
          this.$axios
            .post(
              "/v1/accounts/" +
                this.$store.state.accountid +
                "/api-tokens/" +
                response.data.token_id +
                "/secret"
            )
            .then(response => {
              this.secret = response.data.secret_key;
              this.$root.$emit("bv::hide::modal", "modal-newtoken");
              this.$root.$emit("bv::show::modal", "modal-showsecret");
            });
        });
    },

    editMember(member) {
      for (var u in this.apiTokens) {
        if (member == this.apiTokens[u].user) {
          this.form = this.apiTokens[u];
        }
      }
    },
    async onReset() {
      await this.getData();
      this.form = {};
      this.createForm = {};
      this.$root.$emit("bv::hide::modal", "modal-editmember");
      this.$root.$emit("bv::hide::modal", "modal-newtoken");
    },
    formatUserLevel(level) {
      switch (level) {
        case 0:
          return "Owner";
        case 20:
          return "Admin";
        case 30:
          return "Member";
        case 40:
          return "Installer";
      }
    },
    async getData() {
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());

      await this.$axios
        .get("/v1/accounts/" + this.$store.state.accountid + "/api-tokens")
        .then(response => {
          this.apiTokens = response.data.tokens;
        });
    }
  },
  data() {
    return {
      options: [
        { value: 20, text: "Administrator" },
        { value: 30, text: "Member" },
        { value: 40, text: "Installer" }
      ],
      form: {},
      createForm: {},
      secret: "",
      apiTokens: [],
      memberFields: [
        { key: "name", label: "Name" },
        { key: "created", label: "Created", formatter: "formatDate" },
        { key: "expiration", label: "Expires", formatter: "formatDate" },
        { key: "actions", label: "Actions" }
      ]
    };
  }
};
</script>
