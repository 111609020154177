<template>
  <b-container>
    <footer class="footer text-center p-3">
      <div class="m-2">&copy; Blecon Ltd</div>
      <div class="text-center">
        <a class="mr-2" href="https://developer.blecon.net/" target="_new"
          ><i class="bx bx-book-open"></i> Documentation</a
        >
        <a href="https://support.blecon.net/" target="_new"
          ><i class="bx bx-chat"></i> Support Portal</a
        >
      </div>
    </footer>
  </b-container>
</template>

<style></style>

<script>
export default {};
</script>
