var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.$store.state.initialLoad && _vm.$store.state.accountid)?_c('b-nav-item-dropdown',[_c('template',{slot:"button-content"},[_c('i',{staticClass:"bx bx-sitemap"}),_vm._v(" "+_vm._s(_vm.$store.state.accountName)+" ")]),_c('b-dropdown-group',{attrs:{"id":"dropdown-group-1","header":'Selected account: ' + _vm.$store.state.accountName}},[_c('b-dropdown-item',{attrs:{"to":{
        name: 'accountsettings',
        params: { account: _vm.$store.state.accountid }
      }}},[_vm._v(" Account Information and Settings ")]),(_vm.$store.state.gfwEnabled)?_c('b-dropdown-item',{attrs:{"to":{
        name: 'global_forwarders',
        params: { account: _vm.$store.state.accountid }
      }}},[_vm._v(" Global Forwarders ")]):_vm._e()],1),(_vm.$store.state.accountList.length > 1)?_c('b-dropdown-group',{attrs:{"id":"dropdown-group-1","header":"Switch accounts"}},_vm._l((_vm.$store.state.accountList),function(a){return _c('b-dropdown-item',{key:a.account_id,attrs:{"disabled":a.account_id == _vm.$store.state.accountid,"hidden":a.account_id == _vm.$store.state.accountid},on:{"click":function($event){return _vm.switchAccount(a.account_id)}}},[_vm._v(" "+_vm._s(a.name)+" ")])}),1):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }