<template>
  <b-container>
    <b-breadcrumb>
      <b-breadcrumb-item href="#home">
        <router-link :to="{ name: 'root' }">Home </router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item href="#">
        <router-link
          :to="{ name: 'net_home', params: { networkId: this.api.network_id } }"
          >{{ this.network_name }}
        </router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item>
        Device
      </b-breadcrumb-item>
    </b-breadcrumb>
    <div class="b-main-content">
      <b-modal
        id="modal-2"
        title="Are you sure you want to delete this device? "
        hide-footer
      >
        <p>
          Note that a deleted device can be claimed by another account if they
          have the device ID, which may be found on the device.
        </p>
        <b-button class="float-right" variant="danger" @click="deleteDevice"
          >Delete device from this network</b-button
        >
      </b-modal>
      <b-modal id="modal-1" title="Edit Device" @hidden="onReset" hide-footer>
        <b-form @submit="onSubmit" @reset="onReset">
          <b-form-group
            size="sm"
            id="input-group-1"
            label="Network"
            label-for="input-1"
            description="Network which will receive device requests"
          >
            <b-form-select
              size="sm"
              v-model="form.network_id"
              :options="options"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            size="sm"
            id="input-group-1"
            label="Model"
            label-for="input-1"
            description="Device model name"
          >
            <b-form-input
              size="sm"
              id="input-1"
              v-model="form.model"
              type="text"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            size="sm"
            id="input-group-1"
            label="AP Lock"
            label-for="input-1"
            description="Lock this device to an Access Point"
          >
            <b-form-input
              size="sm"
              id="input-1"
              v-model="form.ap_lock"
              type="text"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            size="sm"
            id="input-group-1"
            label="Description"
            label-for="input-1"
            description="Description of ths device"
          >
            <b-form-input
              size="sm"
              id="input-1"
              v-model="form.description"
              type="text"
            >
            </b-form-input>
          </b-form-group>

          <b-form-group
            size="sm"
            id="input-group-1"
            label="Public Key"
            label-for="input-1"
            description="Public Key of ths device"
          >
            <b-form-input
              size="sm"
              id="input-1"
              v-model="form.public_key"
              type="text"
            >
            </b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary">Save Changes</b-button>
          &nbsp;
          <b-button type="reset">Cancel</b-button>
          <b-button class="float-right" v-b-modal.modal-2 variant="danger"
            >Delete Device</b-button
          >
        </b-form>
      </b-modal>

      <b-card no-body>
        <template #header>
          <h3 class="mb-0">
            Device
          </h3>
        </template>
        <b-card-text class="p-3">
          <h5>{{ this.api.device_id || "Loading..." }}</h5>

          <span class="font-weight-bold">Network: </span>
          <span v-if="this.network_name">
            <router-link
              :to="{
                name: 'net_home',
                params: { networkId: this.api.network_id }
              }"
              >{{ this.network_name }}</router-link
            >
          </span>
          <span v-if="!this.network_name">Not Set</span>

          <p>
            <span class="font-weight-bold">Public Key: </span
            >{{ this.api.public_key || "Not Set" }}
          </p>

          <span class="font-weight-bold">Created: </span
          >{{ this.api.created || "Not Set" }}<br />

          <span class="font-weight-bold">Updated: </span
          >{{ this.api.last_updated || "Not Set" }}<br />

          <span class="font-weight-bold">Last Spotted: </span
          >{{ this.api.last_seen || "Not Set" }}
        </b-card-text>

        <b-card-footer>
          <h5 class="text-danger mb-0">
            Danger Zone
            <b-button
              variant="danger"
              class="float-right"
              :title="
                $store.state.accountLevel > 20 ? 'Insufficent permissions' : ''
              "
              :disabled="$store.state.accountLevel > 20"
              v-b-modal.modal-2
              >Delete</b-button
            >
          </h5>
        </b-card-footer>
      </b-card>
    </div>
  </b-container>
</template>

<script>
//import shared from '@/components/Shared'
import router from "@/router";
import store from "@/store";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import axios from "axios";

export default {
  data() {
    return {
      api: {},
      network_name: "",
      form: {
        model: "",
        network: "",
        notes: "",
        public_key: ""
      },
      options: []
    };
  },
  created() {
    this.loadData();
  },
  mounted() {},
  methods: {
    async loadData() {
      store.commit("spin");

      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());

      var deviceRequest = this.$axios.get(
        "/devices/" + this.$route.params.devId
      );
      var appsRequest = this.$axios.get("/networks");

      Promise.all([deviceRequest, appsRequest])
        .then(values => {
          const device_response = values[0];
          const apps_response = values[1];

          //set up app list
          this.options = [{ value: "", text: "None selected" }];
          for (const a of apps_response.data) {
            this.options.push({ value: a.network_id, text: a.name });
          }

          //set up form
          this.api = device_response.data;
          this.form.model = device_response.data.model;
          this.form.network = device_response.data.network_id;
          this.form.description = device_response.data.description;
          this.form.public_key = device_response.data.public_key;
          var udate = new Date(parseInt(this.api.last_updated) * 1000);
          this.api.last_updated = formatDistance(udate, new Date(), {
            addSuffix: true
          });
          if (this.api.last_seen) {
            var sdate = new Date(parseInt(this.api.last_seen) * 1000);
            this.api.last_seen = formatDistance(sdate, new Date(), {
              addSuffix: true
            });
          }
          var date = new Date(parseInt(this.api.created) * 1000);
          this.api.created = formatDistance(date, new Date(), {
            addSuffix: true
          });

          //set name of configured app instead of id
          for (var app in apps_response.data) {
            if (
              apps_response.data[app].network_id ==
              device_response.data.network_id
            ) {
              this.network_name = apps_response.data[app].name;
            }
          }
          if (this.$route.query.action == "default_landing_page") {
            this.$notify({
              title:
                "Loaded console default device landing page. You can change this behaviour in your network settings.",
              type: "success"
            });
          }
          store.commit("nospin");
        })
        .catch(err => {
          router.push("/404").catch(err => {});
        });
    },
    async onSubmit(event) {
      store.commit("spin");

      this.$root.$emit("bv::hide::modal", "modal-1");
      event.preventDefault();

      this.$axios
        .post(
          "/v1/accounts/" +
            store.state.accountid +
            "/devices/" +
            this.$route.params.devId,
          this.form
        )
        .then(() => {
          this.loadData();
        });
    },
    async deleteDevice() {
      this.$root.$emit("bv::hide::modal", "modal-1");
      this.$root.$emit("bv::hide::modal", "modal-2");
      this.$axios
        .delete(
          "/v1/accounts/" +
            store.state.accountid +
            "/devices/" +
            this.$route.params.devId
        )
        .then(() => {
          this.$notify({
            title: "Device Deleted",
            type: "success"
          });
          router.push({
            name: "net_home",
            params: {
              networkId: this.api.network_id
            }
          });
        });
    },
    onReset(event) {
      this.$root.$emit("bv::hide::modal", "modal-1");

      event.preventDefault();
      // Reset our form values to what we last got from the API
      Object.assign(this.form, this.api);

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>
