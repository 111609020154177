<template>
  <div v-if="!$store.state.initialLoad">
    <notifications position="bottom left" />
    <console-navbar />
    <div v-show="!$store.state.spin">
      <router-view></router-view>
    </div>
    <div v-if="$store.state.spin" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-show="!$store.state.spin">
      <console-footer />
    </div>
  </div>
</template>

<script>
import { bus } from "./main";
import store from "@/store";
import ConsoleNavbar from "@/layout/ConsoleNavbar.vue";
import ConsoleFooter from "@/layout/ConsoleFooter.vue";
import router from "@/router";

export default {
  data() {
    return {
      initialLoad: true
    };
  },
  components: {
    ConsoleNavbar,
    ConsoleFooter
  },
  async created() {
    store.commit("spin");
    store.commit("initialLoad");

    this.loading = true;
    bus.$on("logged", () => {
      this.$log.debug("logged in");
      this.getData();
    });
    bus.$on("reload-accounts", data => {
      this.getData();
    });
  },
  methods: {
    async getData() {
      this.$store.commit("spin");
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
      });

      this.$log.debug(
        "Getting account data. Route account is:",
        params.account
      );

      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());

      await this.$axios.get("/accounts").then(response => {
        var parts = window.location.pathname.split("/");
        if (response.data.length == 0) {
          //if there are no accounts, then either accept invite or create account

          if (
            window.location.pathname.includes("/accept_invite") ||
            window.location.pathname.includes("/accept_invite/")
          ) {
            this.$store.commit("nospin");
            this.initialLoad = false;
            this.$store.commit("loadingFinished");
            this.$log.debug("Allowing invite acceptance");
            return;
          }

          if (
            !["/accept_invite", "/accept_invite/", "/request_account"].includes(
              window.location.pathname
            )
          ) {
            this.$log.debug("Redirecting to create account");
            router.push("/create_account").catch(err => {});
          }
        }

        //see if query param indicates we should switch accounts
        if (params.account) {
          this.$log.debug("Selecting account from qs");
          this.selectAccount(response.data, params.account);
        }
        //we have a pre selected account id
        if (store.state.accountid != undefined) {
          this.$log.debug("Got saved acct id: " + store.state.accountid);
          var selectedAccount = undefined;
          for (var row of response.data) {
            if (row.account_id == store.state.accountid) {
              store.commit("resetState");

              selectedAccount = row.account_id;
              // make sure state is properly populated
              this.$log.debug("Selected account: ", selectedAccount);

              store.commit("selectedAccount", {
                id: row.account_id,
                name: row.name,
                level: row.user_level,
                modelsEnabled: row.models_enabled,
                gfwEnabled: row.global_forwarders_enabled,
                gwEnabled: row.gw_enabled
              });
              this.$log.debug(
                "We have access to the currently selected account, state is set"
              );
            }
          }
          this.$log.debug("Selected account: ", selectedAccount);

          if (selectedAccount == undefined) {
            //lets start again
            store.commit("resetState");

            this.$log.debug(
              "the selected account was not in the list of available accounts"
            );
            this.selectDefault(response.data);
          }
        } else {
          //there is no already selected account ID
          store.commit("resetState");

          this.$log.debug("No selected account, going to default");

          this.selectDefault(response.data);
        }
        store.commit("accountList", {
          accountList: response.data
        });
      });

      this.$log.debug("loading finished");
      this.initialLoad = false;
      this.$store.commit("loadingFinished");

      this.$store.commit("nospin");
      bus.$emit("accounts-reloaded");
    },

    selectDefault(accountList) {
      this.$log.debug("Selecting default from", accountList);

      for (var row of accountList) {
        if (row.user_level == 0) {
          //our default account, save for later
          this.$log.debug("Set account to ", row.account_id);
          this.$log.debug("Account: ", row);

          this.$store.commit("selectedAccount", {
            id: row.account_id,
            name: row.name,
            level: row.user_level,
            modelsEnabled: row.models_enabled,
            gfwEnabled: row.global_forwarders_enabled,
            gwEnabled: row.gw_enabled
          });
          return;
        }
      }
    },
    selectAccount(accountList, accountid) {
      this.$log.debug("Selecting account by id", accountid);

      for (var row of accountList) {
        if (row.account_id == accountid) {
          store.commit("selectedAccount", {
            id: row.account_id,
            name: row.name,
            level: row.level,
            modelsEnabled: row.models_enabled,
            gfwEnabled: row.global_forwarders_enabled,
            gwEnabled: row.gw_enabled
          });
          this.$log.debug("We have now selected account ", row.account_id);
          return;
        }
      }

      this.$log.error("Invalid account selected, reverting to default");
      this.selectDefault(accountList);
    }
  }
};
</script>

<style lang="scss">
.vue-notifyjs.notifications {
  .alert {
    z-index: 10000;
  }
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }
  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
#sentry-feedback {
  --left: 10px;
}
</style>
